// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-aktualnosci-js": () => import("./../../../src/pages/admin-aktualnosci.js" /* webpackChunkName: "component---src-pages-admin-aktualnosci-js" */),
  "component---src-pages-admin-canceled-js": () => import("./../../../src/pages/admin/canceled.js" /* webpackChunkName: "component---src-pages-admin-canceled-js" */),
  "component---src-pages-admin-configure-js": () => import("./../../../src/pages/admin/configure.js" /* webpackChunkName: "component---src-pages-admin-configure-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-panel-js": () => import("./../../../src/pages/admin-panel.js" /* webpackChunkName: "component---src-pages-admin-panel-js" */),
  "component---src-pages-admin-portfolio-js": () => import("./../../../src/pages/admin/portfolio.js" /* webpackChunkName: "component---src-pages-admin-portfolio-js" */),
  "component---src-pages-admin-schedule-js": () => import("./../../../src/pages/admin/schedule.js" /* webpackChunkName: "component---src-pages-admin-schedule-js" */),
  "component---src-pages-admin-stats-js": () => import("./../../../src/pages/admin/stats.js" /* webpackChunkName: "component---src-pages-admin-stats-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rezerwacja-js": () => import("./../../../src/pages/rezerwacja.js" /* webpackChunkName: "component---src-pages-rezerwacja-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

